import {
  Field,
  Form as FormikForm,
  Formik,
  FormikHelpers,
  useFormikContext,
} from "formik";
import { useEffect, useRef } from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import links from "../../../../constants/links";
import { fetchMessages, postMessage } from "../../../../actions/wp";
import { useWpSelector } from "../../../../selectors";
import FormField from "../../../../components/Form/FormField";
import UpgradePlan from "../../../../assets/images/png/buy-higher-plan.png";
import "./MessagesPage.scss";

const MessagesPage = () => {
  const { currentUser } = useWpSelector();
  const dispatch = useDispatch();
  const { messages } = useWpSelector();
  const listRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    dispatch(fetchMessages(String(currentUser?.id), () => {}));

    const interval = setInterval(() => {
      dispatch(fetchMessages(String(currentUser?.id), () => {}));
    }, 30000);

    return () => clearInterval(interval);
  }, [dispatch, currentUser]);

  const sortMsg = messages?.sort((a: any, b: any) => {
    const dateA = new Date(a.created_at);
    const dateB = new Date(b.created_at);
    return dateA.getTime() - dateB.getTime();
  });

  useEffect(() => {
    if (listRef.current) {
      const scrollableList = listRef.current;
      scrollableList.scrollTop = scrollableList.scrollHeight;
    }
  }, [messages]);

  const isPlan30 = currentUser?.meta?.plan === "30";

  return (
    <div className="h-100 message_wr">
      {isPlan30 ? (
        <div className="position-relative h-100 d-flex justify-content-center align-items-center upgrade-plan">
          <div className="w-100 upgrade-plan__msg">
            <p>
              Posiadasz pakiet <strong>ADAPTACJA</strong>. Cieszę się, że mi
              zaufałeś i wybrałeś moją markę do rozpoczęcia przygody ze zdrowiem
              i siłownią. Chcąc cieszyć się i korzystać w 100% z mojej wiedzy,
              monitorowac swoje postępy w oparciu o zdjęcia sylwetki i
              cotygodniowe raporty, mieć osobisty kontakt przez okno czatu 7 dni
              w tygodniu -{" "}
              <strong>
                <Link to={links.panelPackages}>zakup wyższy pakiet</Link>
              </strong>
            </p>
          </div>
          <div className="d-none d-xl-block">
            <img src={UpgradePlan} alt="HEALTH AND BODY ACADEMY" />
          </div>
        </div>
      ) : (
        <>
          <ul className="message_content" ref={listRef}>
            {sortMsg?.map((msg: any) => {
              return (
                <li
                  key={msg.id}
                  className={`${
                    currentUser && +msg.senderId === +currentUser?.id
                      ? "message__author"
                      : "message__caller"
                  }`}>
                  {msg.text.split("\n").map((item: any, key: number) => {
                    return (
                      <span key={key}>
                        {item}
                        <br />
                      </span>
                    );
                  })}
                </li>
              );
            })}
          </ul>
          <div className="message__form_wrapper">
            <Formik
              initialValues={{ text: "" }}
              onSubmit={(
                values: any,
                actions: FormikHelpers<{ text: string }>
              ) => {
                if (values.text && currentUser) {
                  dispatch(
                    postMessage(
                      {
                        receiverId: 1,
                        senderId: currentUser?.id,
                        text: values.text,
                      },
                      (res) => {
                        if (res.status < 300) {
                          actions.setSubmitting(false);
                          actions.setValues({ text: "" });
                          dispatch(
                            fetchMessages(String(currentUser?.id), () => {})
                          );
                        }
                      }
                    )
                  );
                } else {
                  actions.setSubmitting(false);
                }
              }}>
              <FormikForm>
                <Field
                  name="text"
                  type="textarea"
                  placeholder="Aa"
                  rows="2"
                  className="message_text_input"
                  component={FormField}
                />
                <SubmitButton />
              </FormikForm>
            </Formik>
          </div>
        </>
      )}
    </div>
  );
};

const SubmitButton = () => {
  const { submitForm, isSubmitting } = useFormikContext();

  return (
    <Button variant="primary" onClick={submitForm} disabled={isSubmitting}>
      {isSubmitting ? (
        <>
          <span className="spinner-grow spinner-grow-sm" />
        </>
      ) : (
        "WYŚLIJ"
      )}
    </Button>
  );
};

export default MessagesPage;

import axios from "axios";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { ChangeEvent, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { fetchGoal, fetchPlan } from "../../../../actions/wp";
import { tokenHeader } from "../../../../actions/auth";
import Constants from "../../../../constants";
import {
  AvocadoIcon,
  ChartsIcon,
  ChevronIcon,
  FileIcon,
  FishIcon,
  PotatoIcon,
  RefreshIcon,
  ThunderIcon,
} from "../../../../assets/images/svg/Icons";
import RouteHeading from "../../../../components/headings/RouteHeading";
import Loader from "../../../../components/Loader/Loader";
import links from "../../../../constants/links";
import { useWpSelector } from "../../../../selectors";
import DietDay from "./elements/DietDay";
import DietDoc from "./elements/DietDoc";
import { useNutritions } from "./useNutritions";
import "./DietsCreatorPage.scss";

type Meal = string;
type Meals = {
  [key: number]: Meal;
};
type DayPlan = {
  [key: number]: { meals: Meals };
};
export type DietPlan = {
  days: DayPlan;
};

const mealsNumberData = [3, 4, 5];

const defaultDietPlan: DietPlan = {
  days: {
    1: {
      meals: {
        1: "",
        2: "",
        3: "",
      },
    },
    2: {
      meals: {
        1: "",
        2: "",
        3: "",
      },
    },
    3: {
      meals: {
        1: "",
        2: "",
        3: "",
      },
    },
  },
};

const DietsCreatorPage = () => {
  const [dietDays, setDietDays] = useState(3);
  const [mealsNumber, setMealsNumber] = useState(3);
  const [dietPlan, setDietPlan] = useState(defaultDietPlan);
  const [isSaving, setIsSaving] = useState(false);

  const { currentUser, goal, plan } = useWpSelector();
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentUser && currentUser.meta.goal && currentUser.meta.plan) {
      dispatch(fetchGoal(currentUser.meta.goal));
      dispatch(fetchPlan(currentUser.meta.plan));
    }
  }, [currentUser, dispatch]);

  const { calories, carbs, fats, protein } = useNutritions(
    currentUser?.meta.weight,
    goal?.title.rendered,
    currentUser?.meta.gender
  );

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    setDietDays(+e.target.value);
  };

  const handleChangeDays = (type: "up" | "down") => {
    if (type === "up") {
      setDietDays((prev) => prev + 1);

      setDietPlan((prevDietPlan) => {
        const maxDay =
          Math.max(...Object.keys(prevDietPlan.days).map(Number)) + 1;
        const newMeals: any = {};

        for (let i = 1; i <= mealsNumber; i++) {
          newMeals[i] = {};
        }

        const newDay = {
          [maxDay]: { meals: newMeals },
        };

        return {
          ...prevDietPlan,
          days: {
            ...prevDietPlan.days,
            ...newDay,
          },
        };
      });
    } else {
      setDietDays((prev) => prev - 1);

      setDietPlan((prevDietPlan) => {
        let maxDay = Math.max(...Object.keys(prevDietPlan.days).map(Number));
        const { [maxDay]: value, ...remainingDays } = prevDietPlan.days;

        if (Object.keys(remainingDays).length === 0) {
          return prevDietPlan;
        }

        return {
          ...prevDietPlan,
          days: remainingDays,
        };
      });
    }
  };

  const handleChangeMealsNumber = (mealsNum: number) => {
    setMealsNumber(mealsNum);

    setDietPlan((prevDietPlan) => {
      const updatedDays = { ...prevDietPlan.days };

      Object.keys(updatedDays).forEach((dayKey) => {
        const day = updatedDays[+dayKey];
        const currentMealsCount = Object.keys(day.meals).length;

        if (mealsNum > currentMealsCount) {
          for (let i = currentMealsCount + 1; i <= mealsNum; i++) {
            day.meals[i] = "";
          }
        } else if (mealsNum < currentMealsCount) {
          for (let i = currentMealsCount; i > mealsNum; i--) {
            delete day.meals[i];
          }
        }
      });

      return {
        ...prevDietPlan,
        days: updatedDays,
      };
    });
  };

  const handleResetForm = () => {
    setDietDays(3);
    setMealsNumber(3);
  };

  const renderDays = () => {
    const days = [];
    for (let i = 0; i < dietDays; i++) {
      days.push(
        <DietDay
          key={i}
          day={i + 1}
          open={i === 0}
          mealsAmount={mealsNumber}
          carbs={currentUser?.meta.carbs || carbs}
          fats={currentUser?.meta.fats || fats}
          proteins={currentUser?.meta.protein || protein}
          setDietPlan={setDietPlan}
        />
      );
    }
    return days;
  };

  const updateUserMeta = async (userId: any, meta: any) => {
    try {
      const response = await axios.post(
        `${Constants.apiUri}/users/${userId}`,
        { meta },
        tokenHeader()
      );

      return response.data;
    } catch (error) {
      console.error("Błąd przy aktualizacji usermeta:", error);
      throw error;
    }
  };

  const [editing, setEditing] = useState({
    calories: false,
    carbs: false,
    fats: false,
    protein: false,
  });

  const [editableValues, setEditableValues] = useState({
    calories: calories.toFixed(),
    carbs: carbs.toFixed(),
    fats: fats.toFixed(),
    protein: protein.toFixed(),
  });

  type NutrientKey = "calories" | "carbs" | "fats" | "protein";

  const handleEditClick = (nutrient: NutrientKey) => {
    setEditing({ ...editing, [nutrient]: true });
  };

  const handleChangeValue = (
    e: ChangeEvent<HTMLInputElement>,
    nutrient: NutrientKey
  ) => {
    setEditableValues({ ...editableValues, [nutrient]: e.target.value });
  };

  const handleSave = async (nutrient: NutrientKey) => {
    const value = parseFloat(editableValues[nutrient]);
    if (!isNaN(value) && currentUser) {
      setIsSaving(true);
      const updatedMeta = { ...currentUser.meta, [nutrient]: value };
      try {
        await updateUserMeta(currentUser.id, updatedMeta);
        setIsSaving(false);
        setEditing({ ...editing, [nutrient]: false });
      } catch (error) {
        console.error("Błąd podczas zapisywania:", error);
        setIsSaving(false);
      }
    } else {
      console.error("Podana wartość nie jest liczbą");
    }
  };

  const handleCancel = (nutrient: NutrientKey) => {
    setEditableValues((prevValues) => ({
      ...prevValues,
      [nutrient]: currentUser?.meta[nutrient]?.toString() || "",
    }));
    setEditing({ ...editing, [nutrient]: false });
  };

  return (
    <div className="dashboard__diets">
      {currentUser && goal && plan ? (
        <div className="diets">
          <header className="position-relative dashboard__header">
            <RouteHeading>KREATOR DIETY</RouteHeading>
            <p className="mb-0">
              Stwórz pełnowartościowy posiłek z naszym kreatorem, dzięki któremu
              z łatwością ułożysz Twoje żywieniowe puzzle. Zaplanuj dni Twojej
              diety trzema, czterema lub pięcioma posiłkami. Koniec z
              przekąskami!
            </p>
          </header>
          <section className="section__user">
            <div className="user__info">
              <span className="user__item">
                <span>twój wzrost:</span> {currentUser.meta.height} cm
              </span>
              <span className="user__item">
                <span>Twoja waga:</span> {currentUser.meta.weight} kg
              </span>
              <span className="user__item">
                <span>twój wiek:</span> {currentUser.meta.age} lat
              </span>
              <span className="user__item">
                <span>twoja płeć:</span> {currentUser.meta.gender}
              </span>
              <span className="user__item">
                <span>twój cel:</span> {goal?.title.rendered}
              </span>
              <span className="user__item">
                <span>twój pakiet:</span> {plan?.title.rendered}
              </span>
              <Link
                to={links.panelBody}
                className="btn btn-secondary diets-btn">
                ZMIEŃ
              </Link>
            </div>

            <div className="user__demands">
              <p className="user__demands-paragraph">
                <span>TWOJE </span> DZIENNE ZAPOTRZEBOWANIE
              </p>
              <div className="user__demands-icon-wr">
                <ChartsIcon />
              </div>
              <div className="user__demands-nutritions">
                <div className="user__demands-nutritions-item">
                  <span className="user__demands-nutritions-item-value">
                    {editing.calories ? (
                      isSaving ? (
                        <Loader />
                      ) : (
                        <input
                          type="number"
                          value={editableValues.calories}
                          onChange={(e) => handleChangeValue(e, "calories")}
                        />
                      )
                    ) : (
                      `${(currentUser?.meta.calories || calories || 0).toFixed(
                        0
                      )}`
                    )}
                  </span>
                  <span className="user__demands-nutritions-item-cat">
                    Kalorie
                  </span>
                  <ThunderIcon />
                  {currentUser?.meta.plan !== "30" && (
                    <>
                      {editing.calories ? (
                        <>
                          <button onClick={() => handleSave("calories")}>
                            Zapisz
                          </button>
                          <button onClick={() => handleCancel("calories")}>
                            Anuluj
                          </button>
                        </>
                      ) : (
                        <button onClick={() => handleEditClick("calories")}>
                          Zmień
                        </button>
                      )}
                    </>
                  )}
                </div>
                <div className="user__demands-nutritions-item">
                  <span className="user__demands-nutritions-item-value">
                    {editing.fats ? (
                      isSaving ? (
                        <Loader />
                      ) : (
                        <input
                          type="number"
                          value={editableValues.fats}
                          onChange={(e) => handleChangeValue(e, "fats")}
                        />
                      )
                    ) : (
                      `${(currentUser?.meta.fats || fats || 0).toFixed(0)} g`
                    )}
                  </span>
                  <span className="user__demands-nutritions-item-cat">
                    Tłuszcze
                  </span>
                  <AvocadoIcon />
                  {currentUser?.meta.plan !== "30" && (
                    <>
                      {editing.fats ? (
                        <>
                          <button onClick={() => handleSave("fats")}>
                            Zapisz
                          </button>
                          <button onClick={() => handleCancel("fats")}>
                            Anuluj
                          </button>
                        </>
                      ) : (
                        <button onClick={() => handleEditClick("fats")}>
                          Zmień
                        </button>
                      )}
                    </>
                  )}
                </div>
                <div className="user__demands-nutritions-item">
                  <span className="user__demands-nutritions-item-value">
                    {editing.protein ? (
                      isSaving ? (
                        <Loader />
                      ) : (
                        <input
                          type="number"
                          value={editableValues.protein}
                          onChange={(e) => handleChangeValue(e, "protein")}
                        />
                      )
                    ) : (
                      `${(currentUser?.meta.protein || protein || 0).toFixed(
                        0
                      )} g`
                    )}
                  </span>
                  <span className="user__demands-nutritions-item-cat">
                    Białko
                  </span>
                  <FishIcon />
                  {currentUser?.meta.plan !== "30" && (
                    <>
                      {editing.protein ? (
                        <>
                          <button onClick={() => handleSave("protein")}>
                            Zapisz
                          </button>
                          <button onClick={() => handleCancel("protein")}>
                            Anuluj
                          </button>
                        </>
                      ) : (
                        <button onClick={() => handleEditClick("protein")}>
                          Zmień
                        </button>
                      )}
                    </>
                  )}
                </div>
                <div className="user__demands-nutritions-item">
                  <span className="user__demands-nutritions-item-value">
                    {editing.carbs ? (
                      isSaving ? (
                        <Loader />
                      ) : (
                        <input
                          type="number"
                          value={editableValues.carbs}
                          onChange={(e) => handleChangeValue(e, "carbs")}
                        />
                      )
                    ) : (
                      `${(currentUser?.meta.carbs || carbs || 0).toFixed(0)} g`
                    )}
                  </span>
                  <span className="user__demands-nutritions-item-cat">
                    Węglowodany
                  </span>
                  <PotatoIcon />
                  {currentUser?.meta.plan !== "30" && (
                    <>
                      {editing.carbs ? (
                        <>
                          <button onClick={() => handleSave("carbs")}>
                            Zapisz
                          </button>
                          <button onClick={() => handleCancel("carbs")}>
                            Anuluj
                          </button>
                        </>
                      ) : (
                        <button onClick={() => handleEditClick("carbs")}>
                          Zmień
                        </button>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </section>
          <section className="section__creator">
            <h3>
              {" "}
              <span>STWÓRZ</span> PLAN POSIŁKÓW
            </h3>
            <div className="creator__options">
              <div className="creator__options-days">
                <span className="creator__options-days-label">ILOŚĆ DNI: </span>
                <div className="creator__options-days-input">
                  <button
                    disabled={dietDays === 0}
                    className="down"
                    onClick={() => handleChangeDays("down")}>
                    <ChevronIcon />
                  </button>
                  <input
                    type="number"
                    value={dietDays}
                    onChange={handleChangeInput}
                  />
                  <button className="up" onClick={() => handleChangeDays("up")}>
                    <ChevronIcon />
                  </button>
                </div>
              </div>
              <div className="creator__options-meals">
                <span className="creator__options-meals-label">
                  POSIŁKI W DNIU:
                </span>
                <div className="creator__options-meals-number">
                  {mealsNumberData.map((mealNumber) => (
                    <div
                      key={mealNumber}
                      className={`meals_number-item ${
                        mealNumber === mealsNumber && "active"
                      }`}
                      onClick={() => handleChangeMealsNumber(mealNumber)}>
                      {mealNumber}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="creator__days">{renderDays()}</div>
            <div className="creator__notes">
              <p className="my-0">
                <strong>
                  Pamiętaj proszę , żeby codziennie wypijać od 2 do 4 litrów
                  wody. W ciągu całego dnia zjeść 300-400g najlepiej świeżych
                  warzyw, rozłożonych mniej więcej po równo do każdego posiłku.
                  Dodać małego owoca , np jabłko lub banana, do posiłku
                  potreningowego. 2-3 razy w tygodniu jeść ryby.
                </strong>
              </p>
            </div>
            <div className="creator__buttons">
              <Button
                variant="primary"
                className="d-inline-flex justify-content-center align-items-center">
                <PDFDownloadLink
                  document={<DietDoc content={dietPlan} />}
                  fileName="dieta.pdf"
                  className="pdf_link">
                  {({ loading }) => (
                    <span>
                      {loading ? "Pobieranie..." : "GENERUJ .PDF Z DIETĄ"}
                    </span>
                  )}
                </PDFDownloadLink>
                <FileIcon />
              </Button>
              <Button
                variant="secondary"
                type="submit"
                onClick={handleResetForm}>
                <span>ZACZNIJ OD NOWA</span>
                <RefreshIcon />
              </Button>
            </div>
          </section>
        </div>
      ) : (!goal || !plan) && currentUser ? (
        <Loader />
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default DietsCreatorPage;

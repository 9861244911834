import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import RouteHeading from "../../../../components/headings/RouteHeading";
import AddNewForm from "./AddNew/AddNew";
import Reports from "./Reports/Reports";
import { fetchPosts } from "../../../../actions/wp";
import { AddIcon } from "../../../../assets/images/svg/Icons";
import Loader from "../../../../components/Loader/Loader";
import NiceSelect from "../../../../components/NiceSelect/NiceSelect";
import { useWpSelector } from "../../../../selectors";
import "./ReportsPage.scss";

const ReportsPage = () => {
  const dispatch = useDispatch();
  const { currentUser, posts } = useWpSelector();

  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  useEffect(() => {
    if (currentUser) {
      dispatch(fetchPosts(currentUser.id));
    }
  }, [currentUser, dispatch]);

  const [sortOrder, setSortOrder] = useState("newest");

  const handleSortChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSortOrder(event.target.value);
  };

  return (
    <div className="dashboard__reports">
      {currentUser ? (
        <div className="reports">
          <header className="position-relative dashboard__header reports__header">
            <RouteHeading>TWOJE RAPORTY</RouteHeading>
            <p className="mb-0">
              W tym miejscu możesz zarządzać swoimi raportami. Pamiętaj, że
              regularne i sumienne ich wypełnianie ma bezpośredni wpływ na
              jakość Twojego cyklu treningowego. Dzięki nim jestem w stanie
              rzetelnie ocenić Twój progres i dostosowywać zmiany, tak abyś
              osiągnął wymarzone rezultaty.
            </p>
          </header>
          <div className="reports__content">
            <header className="reports__content__header">
              <div className="reports__content__col reports__content__col--left">
                <Button
                  variant="primary"
                  className="d-inline-flex justify-content-center align-items-center"
                  onClick={handleShow}>
                  <span>UTWÓRZ RAPORT</span>
                  <AddIcon />
                </Button>
                <span className="d-block d-md-inline-block m-0">
                  Ilość Twoich raportów:&nbsp;{posts.length}
                </span>
              </div>
              <div className="d-flex justify-content-between align-items-center reports__content__col reports__content__col--right">
                <span>Sortowanie:</span>
                <NiceSelect
                  id="a-select"
                  placeholder="Od najnowszych"
                  className="reports__select"
                  onChange={handleSortChange}>
                  <option value="newest">Od najnowszych</option>
                  <option value="oldest">Od najstarszych</option>
                </NiceSelect>
              </div>
            </header>
            <div className="reports__content__body">
              <Reports sortOrder={sortOrder} />
            </div>
            <AddNewForm
              showModal={showModal}
              handleClose={handleClose}
              handleShow={handleShow}
            />
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default ReportsPage;

import React from "react";
import {
  InfluencerIcon,
  PhoneFooterIcon,
  LoveHandIcon,
  GoalIcon,
} from "../../../assets/images/svg/Icons";
import "./WhyWorthSection.scss";

const WhyWorthSection = () => {
  return (
    <section className="why-worth">
      <div className="container">
        <div className="why-worth__wrapper">
          <header className="text-center why-worth__header">
            <h2 className="font-italic text-uppercase">TRENUJ ZE MNĄ</h2>
            <h3 className="m-0 font-italic text-uppercase">DLACZEGO WARTO?</h3>
          </header>
          <div className="why-worth__content">
            <div className="d-flex align-items-center why-worth__item">
              <div className="svg-container">
                <InfluencerIcon />
              </div>
              <p className="m-0">
                <strong>
                  Kontakt z trenerem 7 dni w <br />
                  tygodniu.
                </strong>{" "}
                Jesteś w dobrych rękach.
              </p>
            </div>
            <div className="d-flex align-items-center why-worth__item">
              <div className="svg-container">
                <PhoneFooterIcon />
              </div>
              <p className="m-0">
                <strong>
                  Cały program przemiany <br />w Twoim telefonie.
                </strong>{" "}
                Wygodniej się nie da!
              </p>
            </div>
            <div className="d-flex align-items-center why-worth__item">
              <div className="svg-container">
                <LoveHandIcon />
              </div>
              <p className="m-0">
                <strong>
                  Trenujesz pod okiem mistrza.
                  <br />
                </strong>{" "}
                Mam ponad 20 lat doświadczenia na siłowni.
              </p>
            </div>
            <div className="d-flex align-items-center why-worth__item">
              <div className="svg-container">
                <GoalIcon />
              </div>
              <p className="m-0">
                <strong>
                  Ciągłe monitorowanie Twojej <br />
                  sylwetki
                </strong>{" "}
                dzięki rozbudowanemu panelowi użytkownika.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyWorthSection;

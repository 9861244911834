import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { SearchIcon } from "../../../../assets/images/svg/Icons";
import Loader from "../../../../components/Loader/Loader";
import NiceSelect from "../../../../components/NiceSelect/NiceSelect";
import Pagination from "../../../../components/Pagination/Pagination";
import TrainerClients from "../../../../components/TrainercClients/TrainerClients";
import Constants from "../../../../constants";
import { useWpSelector } from "../../../../selectors"; // Import useWpSelector
import "./TrainerClientsPage.scss";

const TrainerClientsPage = () => {
  const token = localStorage.getItem("_wp_react_typescipt_token");
  const dispatch = useDispatch();
  const { currentUser } = useWpSelector(); // Get currentUser
  const [clients, setClients] = useState([]);
  const [error, setError] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [sortValue, setSortValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  useEffect(() => {
    const fetchLatestUsers = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${Constants.apiUri}/clients`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            per_page: itemsPerPage,
            orderby: "date",
            order: "desc",
            page: currentPage,
            search: searchValue.length > 2 ? searchValue : "",
            sort_order: sortValue === "plan" ? "plan" : "ASC",
          },
        });
        setTotalPages(response.data.total_pages);
        setClients(response.data.users);
        setError("");
      } catch (err) {
        setError("Przepraszamy, coś poszło nie tak, spróbuj ponownie później");
      } finally {
        setIsLoading(false);
      }
    };

    fetchLatestUsers();
  }, [token, itemsPerPage, currentPage, searchValue, sortValue]);

  const handleSearchClient = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 2 || searchValue) {
      setSearchValue(e.target.value);
      setCurrentPage(1);
    }
  };

  const handleSortChange = (e: any) => {
    setSortValue(e.target.value);
  };

  const handleClickPage = (page: number) => {
    setCurrentPage(page);
  };

  return clients ? (
    <div className="dashboard__reports">
      <div className="reports">
        <div className="reports__content">
          <header className="reports__content__header">
            <div className="reports__content__col reports__content__col--left">
              <div className="input_search">
                <input
                  type="text"
                  placeholder="Wyszukaj klienta"
                  onChange={handleSearchClient}
                />
                <SearchIcon />
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center reports__content__col reports__content__col--right">
              <span>Sortowanie:</span>
              <NiceSelect
                id="a-select"
                placeholder="Od najnowszych"
                className="reports__select"
                onChange={handleSortChange}>
                <option value="plan">Rodzaj pakietu</option>
                <option value="newest">Od najnowszych</option>
              </NiceSelect>
            </div>
          </header>
          <div className="reports__content__body cust_list">
            <TrainerClients
              clients={clients ? clients : []}
              error={error}
              isLoading={isLoading}
              currentUser={currentUser} // Pass currentUser here
            />
          </div>
        </div>
      </div>
      <Pagination
        onNextPage={() => setCurrentPage(currentPage + 1)}
        onPrevPage={() => setCurrentPage(currentPage - 1)}
        currentPage={currentPage}
        onClickPage={handleClickPage}
        itemsQuantityAvaliabl={[5, 10, 15]}
        onClickItemsQuantity={(quantity) => setItemsPerPage(quantity)}
        quantityPerPage={itemsPerPage}
        totalPages={totalPages}
      />
    </div>
  ) : (
    <Loader />
  );
};

export default TrainerClientsPage;

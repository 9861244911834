import RootLayout from "../../layouts/RootLayout";
import PagesBreadcrumbs from "../../components/PagesBreadcrumbs/PagesBreadcrumbs";
import Hero from "./sections/Hero";
import ResultsSlider from "../HomePage/sections/ResultsSliderSection";
import Faq from "../../components/Faq/Faq";
import Cta from "../../components/Cta/Cta";
import "./PackagesPage.scss";
import PackagesSection from "./sections/PackagesSection";

const PackagesPage = () => {
  return (
    <RootLayout>
      <main id="main" className="packages_main">
        <PagesBreadcrumbs />
        <Hero />
        <PackagesSection />
        <ResultsSlider
          title="TO DZIAŁA!"
          desc="Praca ze mną to przygoda, która przyniesie Ci niesamowite rezultaty. Ale nie musisz wierzyć na słowo. Zobacz metamorfozy moich podopiecznych."
          darkBg={true}
        />
        <Faq />
        <Cta />
      </main>
    </RootLayout>
  );
};

export default PackagesPage;

import React from "react";
import { Card } from "react-bootstrap";
import { Product } from "../../actions/wp";
import { CheckIcon } from "../../assets/images/svg/Icons";

type ItemProps = {
  product: Product;
  onClick: (name: string) => void;
  addToCart: (productId: number) => void;
  formValues?: any;
};

const Item: React.FC<ItemProps> = React.memo(
  ({ product, onClick, addToCart, formValues }) => {
    const description = product.acf.acf_product.description;
    const list = product.acf.acf_product.list;
    const text = product.acf.acf_product.text;

    const handleClick = () => {
      let productIdAsString: string;

      switch (product.name) {
        case "ADAPTACJA":
          productIdAsString = "30";
          break;
        case "TURBO":
          productIdAsString = "29";
          break;
        case "SUPER CHARGED":
          productIdAsString = "28";
          break;
        case "X-TREME":
          productIdAsString = "27";
          break;
        case "DOMOWY":
          productIdAsString = "1652";
          break;
        case "W GÓRĘ SERCA":
          productIdAsString = "1653";
          break;
        case "MASTER BIZNES":
          productIdAsString = "1761";
          break;
        default:
          productIdAsString = "30";
      }

      onClick(productIdAsString);
      addToCart(product.id);
    };

    //meta_data:
    const priceMeta = product.meta_data.find(
      (meta) => meta.key === "_subscription_price"
    );
    const periodMeta = product.meta_data.find(
      (meta) => meta.key === "_subscription_period"
    );
    const periodIntervalMeta = product.meta_data.find(
      (meta) => meta.key === "_subscription_period_interval"
    );

    const price = priceMeta ? priceMeta.value : null;

    const translatePeriod = (period: string) => {
      switch (period) {
        case "Day":
          return "dzień";
        case "Month":
          return "miesiąc";
        case "Year":
          return "rok";
        default:
          return period;
      }
    };

    const formatPeriodInterval = (period: string, interval: number) => {
      if (period === "year" && interval === 1) {
        return "12 miesięcy";
      } else if (period === "month") {
        return interval === 1 ? "1 miesiąc" : `${interval} miesiące`;
      } else {
        return `${interval} ${translatePeriod(period)}${
          interval > 1 ? "y" : ""
        }`;
      }
    };

    const periodValue = periodMeta ? periodMeta.value.toString() : null;
    const periodIntervalValue = periodIntervalMeta
      ? Number(periodIntervalMeta.value)
      : 0;
    const fullPeriodDisplay = periodValue
      ? formatPeriodInterval(periodValue, periodIntervalValue)
      : null;

    return (
      <Card
        className={`position-relative border-0 ${
          formValues?.productMetaData
            ? +Object.keys(formValues?.productMetaData)[0] === +product.id
              ? "is-active"
              : ""
            : ""
        }`}
        tabIndex={1}
        onClick={handleClick}>
        <Card.Body>
          <header className="bg-transparent text-center font-italic p-0 border-0 rounded-0 card-header">
            <h3 className="mt-0 text-uppercase card-title">{product.name}</h3>
            <time className="font-italic text-uppercase card-time">
              {fullPeriodDisplay}
            </time>
          </header>
          <div className="card-content">
            {description && (
              <div className="text-center card-description">
                <p className="mb-0">{description}</p>
              </div>
            )}
            <p className="card-text">
              <strong>{text}</strong>
            </p>
            <ul className="list-unstyled m-0 p-0 card-list">
              {list.map((el, idx) => (
                <li className="position-relative card-list__item" key={idx}>
                  <div className="position-absolute d-flex align-items-center svg-container">
                    <CheckIcon />
                  </div>
                  <span
                    className="d-block"
                    dangerouslySetInnerHTML={{ __html: el.item }}></span>
                </li>
              ))}
            </ul>
          </div>
          <footer className="bg-transparent border-0 rounded-0 p-0 card-footer">
            <div className="d-flex justify-content-center align-items-center card-footer__wrapper">
              <span className="font-italic price">
                {typeof price === "string"
                  ? `${parseFloat(price).toFixed(2)} PLN`
                  : price && `${price.day} ${price.month}`}
              </span>
              {/* <span className="font-italic price--original">
                {original_price}
              </span> */}
            </div>
          </footer>
        </Card.Body>
      </Card>
    );
  }
);

export default Item;

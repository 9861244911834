import RootLayout from "../../layouts/RootLayout";
import "./NotFoundPage.scss";
import React from "react";
import PageHeading from "../../components/headings/PageHeading";
import LinkButton from "../../components/Buttons/LinkButton";
import links from "../../constants/links";

const NotFoundPage = () => {
  return (
    <RootLayout>
      <main
        id="main"
        className="d-flex align-items-center container_not_found flex-column">
        <header className="d-flex align-items-center hero__header">
          <h1 className="font-italic my-0 center">ERROR 404</h1>
        </header>
        <PageHeading variant="h2">NIE MAM TAKIEJ STRONY</PageHeading>
        <p>
          Wygląda na to, że próbowałeś/aś zawędrować w rejony mojej strony,
          które (przynajmniej na razie) są niezagospodarowane :) Jeśli chcesz
          zaprowadzę Cię z powrotem na stronę główną.
        </p>
        <LinkButton
          type="link"
          variant="primary"
          text="DO STRONY GŁÓWNEJ"
          href={links.home}
        />
      </main>
    </RootLayout>
  );
};

export default NotFoundPage;

import RootLayout from "../../layouts/RootLayout";
import PagesBreadcrumbs from "../../components/PagesBreadcrumbs/PagesBreadcrumbs";
import PageHeading from "../../components/headings/PageHeading";
import "./PrivacyPolicyPage.scss";

const PrivacyPolicyPage = () => {
  return (
    <RootLayout>
      <main id="main">
        <PagesBreadcrumbs />
        <section className="terms">
          <div className="container">
            <div className="terms__wrapper">
              <header className="terms__header">
                <PageHeading variant="h1">KONIECZNIE SIĘ ZAPOZNAJ!</PageHeading>
                <PageHeading variant="h2">POLITYKA PRYWATNOŚCI</PageHeading>
                <p>
                  <strong>
                    Postanowienia poniższe stanowią politykę prywatności (dalej:
                    „Polityka Prywatności”), która określa informacje dotyczące
                    zbierania danych, w tym danych osobowych użytkowników
                    usług(i) świadczonej przez MICHAŁ BOBYK z siedzibą przy ul.
                    Sienna 6/8b, 35-326 Rzeszów , o numerze NIP: 8133399548 oraz
                    sposób ich przetwarzania.
                  </strong>
                </p>
              </header>
              <div className="terms__content">
                <PageHeading variant="h3">
                  Informacja o ochronie danych osobowych
                </PageHeading>
                <p>
                  <strong>
                    Michał Bobyk z siedzibą w Rzeszowie (35-326), przy ul.
                    Sienna 6/8b jako administrator danych osobowych, gromadzi
                    Państwa dane osobowe uzyskane podczas zawierania z Państwem
                    umowy oraz w trakcie jej trwania, w szczególności:
                  </strong>
                </p>
                <ol type="1">
                  <li>
                    imię (imiona) i nazwisko, adres e-mail, adres do kontaktu,
                    firma reprezentowanego podmiotu, numer telefonu, stanowisko.
                  </li>
                </ol>
                <p>
                  <strong>
                    Gromadzimy Państwa Dane osobowe w następujących celach:
                  </strong>
                </p>
                <div
                  dangerouslySetInnerHTML={{
                    __html: `
                  <ol start="2">
                    <li>zawierania i realizacji łączącej nas umowy oraz świadczenia dla Państwa oferowanych przez nas usług,</li>
                    <li>realizacji obowiązków przewidzianych prawem, w tym w szczególności wystawiania i przechowywania faktur oraz dokumentów księgowych, oraz dla celów archiwizacyjnych,</li>
                    <li>ochrony praw Michał Bobyk zgodnie z przepisami prawa, w tym w szczególności dochodzenia zaległych płatności w ramach procesów windykacyjnych i procesów sądowych,</li>
                    <li>przeprowadzania działań marketingowych oraz przesyłania informacji handlowych w przypadku wyrażenia przez Państwa osobnych zgód,</li>
                    <li>w celu udzielenia odpowiedzi na przesłane zapytanie, w zależności od udzielonej zgody</li>
                  </ol>
                `,
                  }}
                />
                <p>
                  <strong>
                    Michał Bobyk może przekazywać Dane osobowe następującym
                    osobom trzecim dla celów wskazanych w niniejszym dokumencie:
                  </strong>
                </p>
                <div
                  dangerouslySetInnerHTML={{
                    __html: `
                  <ol start="7">
                    <li>podmiotom, z którymi ma zawartą umowę współpracy (<strong>” Przetwarzający dane „</strong>) w celu realizacji łączącej nas umowy, realizacji obowiązków przewidzianych prawem, ochrony praw zgodnie z przepisami prawa oraz realizacji uzasadnionego interesu w rozumieniu przepisów o ochronie danych osobowych; w szczególności Michał Bobyk może przekazywać Państwa Dane osobowe podmiotom takim jak: banki, firmy windykacyjne, podmioty świadczące usługi księgowe, spółki świadczące usługi pocztowe i kurierskie, firmy, z którymi współpracuję w celu świadczenia usług marketingowych.  Podmioty takie będą zobowiązane na mocy zawartych z Michał Bobyk umów do stosowania odpowiednich środków bezpieczeństwa, technicznych i organizacyjnych, aby chronić Dane osobowe oraz przetwarzać je wyłącznie zgodnie z instrukcjami przekazanymi przez Michał Bobyk,</li>
                    <li>organom nadzorującym, organom władzy i innym osobom trzecim; w przypadku gdy jest to niezbędne dla realizacji celów wskazanych powyżej  oraz wypełnienia obowiązków nałożonych prawem, Dane osobowe mogą być przekazywane organom nadzorującym, sądom i innym organom władzy (np. organom podatkowym i organom ścigania), niezależnym doradcom zewnętrznym (np. audytorom) lub podmiotom udzielającym świadczeń</li>
                  </ol>
                `,
                  }}
                />
                <PageHeading variant="h3">
                  Uprawnienia użytkowników w zakresie danych osobowych
                </PageHeading>
                <div
                  dangerouslySetInnerHTML={{
                    __html: `
                  <ol start="9">
                    <li>Posiada Pan/Pani prawo żądania od Administratora dostępu do danych, które Pana/Pani dotyczą, ich sprostowania, usunięcia lub ograniczenia przetwarzania. Posiada Pan/Pani prawo do wniesienia sprzeciwu wobec przetwarzania oraz prawo do przenoszenia danych,</li>
                    <li>Posiada Pan/Pani uprawnienie do cofnięcia zgody udzielonej na przetwarzanie danych,</li>
                    <li>Posiada Pan/Pani prawo do wniesienia skargi do organu nadzorczego właściwego do spraw ochrony danych osobowych</li>
                  </ol>
                `,
                  }}
                />
                <PageHeading variant="h3"> Inne postanowienia:</PageHeading>
                <div
                  dangerouslySetInnerHTML={{
                    __html: `
                  <ol start="12">
                    <li>Administrator nie zamierza przekazywać Twoich danych do państwa trzeciego ani do organizacji międzynarodowych,</li>
                    <li>Podanie danych jest dobrowolne, lecz niezbędne do wykonania usługi. Brak podania danych osobowych uniemożliwi wykonanie usługi,</li>
                    <li>Pana/Pani dane osobowe nie będą przedmiotem zautomatyzowanego podejmowania decyzji, w tym profilowania</li>
                    <li>Dane osobowe będą przechowywane przez Michał Bobyk wyłącznie przez czas niezbędny do osiągnięcia celów, dla których dane te są gromadzone, wykonania obowiązków wynikających z przepisów prawa, maksymalnie przez okres zabezpieczenia materiałów niezbędnych dla postępowań prawnych (w tym podatkowych) oraz do czasu ewentualnego przedawnienia roszczeń Państwa i Michał Bobyk,</li>
                    <li>Michał Bobyk zastrzega sobie prawo do wprowadzania zmian w Polityce Prywatności. Każdego użytkownika serwisu należącego do Michał Bobyk obowiązuje aktualna Polityka Prywatności</li>
                  </ol>
                `,
                  }}
                />
              </div>
              <footer className="text-center terms__footer">
                <a
                  className="position-relative d-inline-block text-decoration-none"
                  href="/">
                  <div className="position-relative d-flex align-items-center svg-container">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M15.8337 10H4.16699"
                        stroke="white"
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M10.0003 15.8337L4.16699 10.0003L10.0003 4.16699"
                        stroke="white"
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <span className="d-inline-block font-italic text-uppercase">
                      Wróć
                    </span>
                  </div>
                </a>
              </footer>
            </div>
          </div>
        </section>
      </main>
    </RootLayout>
  );
};

export default PrivacyPolicyPage;

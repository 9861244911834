import { Link, useLocation } from "react-router-dom";
import LogoPng from "../../assets/images/png/logoV2.png";
import MichalStopka from "../../assets/images/png/michal-bobyk-2.png";
import {
  FacebookIcon2,
  InstaIcon2,
  MessageIcon2,
  ReturnArrow,
} from "../../assets/images/svg/Icons";
import links from "../../constants/links";
import WorthElements from "../WorthElements/WorthElements";
import "./Footer.scss";

const Footer = () => {
  const location = useLocation();

  if (location.pathname.includes(links.signUp))
    return (
      <footer className="footer__signup">
        <WorthElements variant="secondary" />
      </footer>
    );

  const handleScrollTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-top">
          <div className="footer__col--left">
            <div className="footer__logo">
              <div className="position-relative footer__logo__wrapper">
                <a href="/">
                  <img src={LogoPng} alt="HEALTH AND BODY ACADEMY" />
                </a>
              </div>
              <h2 className="font-italic">HEALTH AND BODY ACADEMY</h2>
            </div>
            <div className="footer__contacts">
              <a
                className="d-inline-flex align-items-center"
                href="mailto:info@healthandbodyacademy.com">
                <MessageIcon2 />
                <span>info@healthandbodyacademy.com</span>
              </a>
              <a
                className="d-inline-flex align-items-center"
                href="https://www.facebook.com/healthandbodyacademy"
                rel="noreferrer"
                target="_blank">
                <FacebookIcon2 />
                <span>/healthandbodyacademy</span>
              </a>
            </div>
          </div>
          <div className="footer__col--center">
            <div className="trainer">
              <div className="trainer__avatar">
                <img src={MichalStopka} alt="MICHAŁ BOBYK" />
              </div>
              <div className="trainer__details">
                <h3 className="font-italic">
                  MICHAŁ <br />
                  BOBYK
                </h3>
                <div className="trainer__contact">
                  <a href="tel:530266311">tel. +48 530 266 311</a>
                </div>
                <div className="trainer__instagram">
                  <a
                    className="d-inline-flex align-items-center"
                    href="https://www.instagram.com/michalbobyk"
                    target="_blank"
                    rel="noreferrer">
                    <InstaIcon2 />
                    <span>/michalbobyk</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="footer__col--right">
            <nav className="footer__nav">
              <ul className="list-unstyled menu-pages">
                <li>
                  <Link to={links.packages}>PAKIETY</Link>
                </li>
                <li>
                  <Link to={links.aboutUs}>O MNIE</Link>
                </li>
                <li>
                  <Link to={links.metamorphoses}>WASZE METAMORFOZY</Link>
                </li>
                <li>
                  <Link to={links.faq}>FAQ</Link>
                </li>
                <li>
                  <Link to={links.blog}>BLOG</Link>
                </li>
                <li>
                  <Link to={links.contact}>KONTAKT</Link>
                </li>
                <li>
                  <Link to={links.contact}>MÓJ PANEL</Link>
                </li>
              </ul>
              <ul className="list-unstyled menu-policies">
                <li>
                  <Link to={links.privacyPolicy}>POLITYKA PRYWATNOŚCI</Link>
                </li>
                <li>
                  <Link to={links.cookiesPolicy}>POLITYKA COOKIES</Link>
                </li>
                {/* <li>
                  <Link to={links.terms}>REGULAMIN STRONY</Link>
                </li> */}
                <li>
                  <Link to={links.rules}>REGULAMIN TRENINGÓW</Link>
                </li>
                <div className="d-none d-lg-block footer__return">
                  <span
                    className="d-flex align-items-center text-decoration-none"
                    onClick={handleScrollTop}>
                    <div className="svg-container">
                      <ReturnArrow />
                    </div>
                    <span className="d-block">Wróć na górę</span>
                  </span>
                </div>
              </ul>
            </nav>
            <div className="d-block d-lg-none footer__return">
              <span className="text-decoration-none" onClick={handleScrollTop}>
                <div className="svg-container">
                  <ReturnArrow />
                </div>
                <span className="d-block">Wróć na górę</span>
              </span>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <span className="d-block">
            &copy; 2024 HealthAndBodyAcademy | All Rights Reserved
          </span>
          <span className="d-block">TRENUJ POD OKIEM MISTRZA</span>
          <span className="d-block">Created by Michał Bobyk</span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
